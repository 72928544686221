// ** Icon imports
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EmailIcon from '@mui/icons-material/Email';
import HistoryIcon from '@mui/icons-material/History';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AccountCogOutline from 'mdi-material-ui/AccountCogOutline';
import CubeOutline from 'mdi-material-ui/CubeOutline';
import HomeOutline from 'mdi-material-ui/HomeOutline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

// ** Type import
import { VerticalNavItemsType } from '@core/layouts/types';
import { ROLE } from 'constants/role.constants';

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: 'Dashboard',
      icon: HomeOutline,
      path: '/',
    },
    ...ManagerHome,
    ...Account,
    ...Voucher,
    // ...Notification,
    // ...Requets,
    // ...Offers,
    // ...Message,
    ...Expert,
    ...Notification,
    ...Accountant,
    ...Permission,
    // {
    //   sectionTitle: 'Pages',
    // },
    // {
    //   title: 'Login',
    //   icon: Login,
    //   path: '/login',
    //   openInNewTab: true,
    // },
    // {
    //   title: 'Register',
    //   icon: AccountPlusOutline,
    //   path: '/register',
    //   openInNewTab: true,
    // },
    // {
    //   title: 'Error',
    //   icon: AlertCircleOutline,
    //   path: '/error',
    //   openInNewTab: true,
    // },
    // {
    //   sectionTitle: 'User Interface',
    // },
    // {
    //   title: 'Typography',
    //   icon: FormatLetterCase,
    //   path: '/typography',
    // },
    // {
    //   title: 'Icons',
    //   path: '/icons',
    //   icon: GoogleCirclesExtended,
    // },
    // {
    //   title: 'Cards',
    //   icon: CreditCardOutline,
    //   path: '/cards',
    // },
    // {
    //   title: 'Tables',
    //   icon: Table,
    //   path: '/tables',
    // },
    // {
    //   icon: CubeOutline,
    //   title: 'Form Layouts',
    //   path: '/form-layouts',
    // },
  ];
};

const Requets: VerticalNavItemsType = [
  {
    sectionTitle: 'Request',
  },
  {
    icon: CubeOutline,
    groupTitle: 'Yêu cầu dịch vụ',
    path: '/request',
    list: [
      {
        title: 'Yêu cầu',
        icon: AccountCogOutline,
        path: '/request?type=1&status=-3',
      },
      {
        title: 'Yêu cầu hủy gói',
        icon: AccountCogOutline,
        path: '/request/cancel',
      },
    ],
  },
];
const Offers: VerticalNavItemsType = [
  {
    sectionTitle: 'Offers',
  },
  {
    icon: CubeOutline,
    groupTitle: 'Gói dịch vụ',
    path: '/request',
    list: [
      {
        title: 'Danh sách dịch vụ',
        icon: AccountCogOutline,
        path: '/offers',
      },
      {
        title: 'DS Dvụ chưa được liên hệ',
        icon: AccountCogOutline,
        path: '/offer/offerNotCalled',
      },
      {
        title: 'Khiếu nại dịch vụ',
        icon: AccountCogOutline,
        path: '/request/reports?status=0',
      },
    ],
  },
];
const Voucher: VerticalNavItemsType = [
  {
    sectionTitle: 'Voucher',
    permissions: ROLE.ROLE_ACCOUNT,
  },
  // {
  //   title: 'Danh sách voucher',
  //   icon: ConfirmationNumberIcon,
  //   path: '/vouchers',
  // },

  {
    title: 'Lịch sử voucher',
    icon: HistoryIcon,
    path: '/vouchers/history',
    permissions: ROLE.ROLE_ACCOUNT,
  },
  {
    title: 'Voucher chuyên gia',
    icon: ConfirmationNumberIcon,
    path: '/vouchers/expert',
    permissions: ROLE.ROLE_ACCOUNT,
  },
  {
    title: 'Voucher người dùng',
    icon: ConfirmationNumberIcon,
    path: '/vouchers/user',
    permissions: ROLE.ROLE_ACCOUNT,
  },
  {
    title: 'Tạo voucher',
    icon: AddIcon,
    path: '/vouchers/create',
    permissions: ROLE.ROLE_ACCOUNT,
  },
];

const ManagerHome: VerticalNavItemsType = [
  {
    sectionTitle: 'Layout',
  },
  {
    title: 'Trang chủ',
    icon: HomeOutline,
    path: '/layouts',
  },
];

const Account: VerticalNavItemsType = [
  {
    sectionTitle: 'Quản lý tài khoản',
    permissions: ROLE.ROLE_ACCOUNT,
  },
  {
    title: 'Danh sách tài khoản',
    icon: PeopleIcon,
    path: '/accounts',
    // permissions: [1],
    permissions: ROLE.ROLE_ACCOUNT,
  },
  {
    title: 'Tạo tài khoản',
    icon: PersonAddIcon,
    path: '/accounts/create',
    // permissions: [0],
    permissions: ROLE.ROLE_ACCOUNT,
  },
];
const Accountant: VerticalNavItemsType = [
  {
    sectionTitle: 'Kế toán',
    permissions: ROLE.ROLE_COUNTANT,
  },
  {
    title: 'Kế toán',
    icon: AttachMoneyIcon,
    path: '/accountant',
    permissions: ROLE.ROLE_COUNTANT,
  },
];
const Message: VerticalNavItemsType = [
  {
    sectionTitle: 'Message',
  },
  {
    icon: EmailIcon,
    groupTitle: 'Message',
    path: '/messages',
    list: [
      {
        title: 'Tin nhắn support',
        icon: EmailIcon,
        path: '/messages',
      },
      {
        title: 'DS ghi âm',
        icon: EmailIcon,
        path: '/messages',
      },
      {
        title: 'DS ko trả lời sau 24h',
        icon: EmailIcon,
        path: '/messages',
      },
      {
        title: 'khách hàng-chuyên gia',
        icon: EmailIcon,
        path: '/messages',
      },
    ],
  },
];
const Notification: VerticalNavItemsType = [
  {
    sectionTitle: 'Thông báo',
    permissions: ROLE.ROLE_NOTIFY,
  },
  {
    title: 'Tạo thông báo',
    icon: NotificationAddIcon,
    path: '/notifications/create',
    permissions: ROLE.ROLE_NOTIFY,
    // permissions: [13],
  },
  {
    title: 'Lịch sử thông báo',
    icon: NotificationsActiveIcon,
    path: '/notifications',
    // permissions: [15],
    permissions: ROLE.ROLE_NOTIFY,
  },
];
const Permission: VerticalNavItemsType = [
  {
    sectionTitle: 'Quản lý quyền',
    permissions: ROLE.ROLE_PERMISSION,
  },
  {
    title: 'Danh sách role',
    icon: PeopleIcon,
    path: '/roles',
    permissions: ROLE.ROLE_PERMISSION,
    // permissions: [5],
  },
  {
    title: 'Danh sách nhóm quyền',
    icon: PeopleIcon,
    path: '/permission-groups',
    permissions: ROLE.ROLE_PERMISSION,
    // permissions: [10],
  },
];

const Expert: VerticalNavItemsType = [
  {
    sectionTitle: 'Chuyên gia',
    permissions: ROLE.ROLE_ACCOUNT,
  },
  {
    title: 'Flash sale',
    icon: MonetizationOnIcon,
    path: '/experts/sale',
    permissions: ROLE.ROLE_ACCOUNT,
  },
  {
    title: 'Danh sách chuyên gia',
    icon: PeopleIcon,
    path: '/experts',
    permissions: ROLE.ROLE_ACCOUNT,
  },
];

export default navigation;
